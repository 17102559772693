@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  
  .animate-bounce {
    animation: bounce 2s infinite;
  }
  
  @keyframes clouds {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  .animate-clouds {
    animation: clouds 30s linear infinite;
  }
  
  .bg-clouds {
    background-image: url('https://www.transparenttextures.com/patterns/cloudy.png');
  }